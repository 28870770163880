import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import ReactHtmlParser from 'react-html-parser'
import { HelmetDatoCms } from 'gatsby-source-datocms'

import Layout from '../components/layout'

class WashspacesSection extends React.Component {
  render () {
    const {
      datoCmsWashspacesSection: section,
      allDatoCmsCaseStudy: caseStudies
    } = this.props.data
    const allCaseStudies = () => {
      if (!caseStudies) {
        return (
          <div className="column has-text-centered">
            <p>Case studies coming soon...</p>
          </div>
        )
      }
      return caseStudies.edges.map(({ node: caseStudy }) => (
        <Link
          to={`/case-study/${caseStudy.slug}`}
          key={caseStudy.id}
          className="column is-half"
          style={{ marginBottom: '2rem', padding: '0.5rem' }}
        >
          <figure className="image is-4by3" style={{ marginBottom: '1rem' }}>
            <Img
              style={{ maxHeight: '100%', maxWidth: '100%' }}
              fixed={caseStudy.heroBackgroundImage.fixed}
              alt={caseStudy.title}
            />
          </figure>
          <h3 className="subtitle is-size-6 has-text-weight-bold">
            {caseStudy.title}
          </h3>
        </Link>
      ))
    }
    return (
      <Layout>
        <HelmetDatoCms seo={section.seoMetaTags} />
        <section
          className="hero is-large is-page-hero"
          style={{
            backgroundImage: `url(${section.heroImage.fixed.src})`
          }}
        >
          <div className="hero-body">
            <div className="container">
              <h1 className="title has-text-white has-text-weight-normal">
                {section.heroTitle}
              </h1>
            </div>
          </div>
        </section>
        <section className="hero">
          <div className="hero-body">
            <div className="container">
              <h4 className="subtitle is-size-4 has-text-weight-bold">
                {ReactHtmlParser(section.textNode.childMarkdownRemark.html)}
              </h4>
            </div>
          </div>
        </section>
        <div className="container" style={{ marginBottom: '4rem' }}>
          <div className="columns is-multiline">{allCaseStudies()}</div>
        </div>
      </Layout>
    )
  }
}

WashspacesSection.propTypes = {
  data: PropTypes.object.isRequired
}

export default WashspacesSection

export const query = graphql`
  query WashspacesSectionQuery($slug: String!) {
    datoCmsWashspacesSection(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroTitle
      heroImage {
        fixed(
          width: 1920
          height: 600
          imgixParams: {
            h: "800"
            auto: "compress"
            fm: "pjpg"
            fit: "crop"
            crop: "focalpoint"
            bri: -10
          }
        ) {
          src
        }
      }
      textNode {
        childMarkdownRemark {
          html
        }
      }
    }
    allDatoCmsCaseStudy(
      filter: { sector: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          id
          title
          slug
          heroBackgroundImage {
            fixed(
              width: 600
              height: 400
              imgixParams: { fm: "pjpg", auto: "compress", crop: "entropy", fit: "crop" }
            ) {
              ...GatsbyDatoCmsFixed
            }
          }
        }
      }
    }
  }
`
